
import {Cell, Column, Row, TableView, TableBody, TableHeader} from '@adobe/react-spectrum'
import { useState } from 'react';
import { Util } from '../../utils/Util';


interface ListProps {
    columns: Array<any>,
    list: any,
    setSelectedKey?:any
    uniqueKey:string;
}

let ListView = (props:ListProps) => {

    return (
        <>
        <TableView  selectionMode="single" selectionStyle="highlight"
         onSelectionChange={props.setSelectedKey} >
            <TableHeader columns={props.columns}>
                {(column) => (
                <Column>
                    {column.name}
                </Column>
                )}
            </TableHeader>
            <TableBody items={props.list.items} loadingState={props.list.loadingState}>
                {(item: any) => (
                <Row key={item[props.uniqueKey]}>
                    {(columnKey) => <Cell>{Util.getData(item, columnKey.toString())}</Cell>}
                </Row>
                )}
            </TableBody>
        </TableView>
        </>
    );
};

export default ListView;