import Draw from '@spectrum-icons/workflow/Draw';
import EmailLightning from '@spectrum-icons/workflow/EmailLightning';
import Targeted from '@spectrum-icons/workflow/Targeted';
import WebPage from '@spectrum-icons/workflow/WebPage';
import FileJson from '@spectrum-icons/workflow/FileJson';
import Delete from '@spectrum-icons/workflow/Delete';
import Chat from '@spectrum-icons/workflow/Chat';
import ViewDetail from '@spectrum-icons/workflow/ViewDetail';
import { DELETE, EDIT, LH_SCORE, PREVIEW_CHAT, PREVIEW_JSON, PREVIEW_WEB, RESTART, TARGET } from './Constant';


export const FormActions = [
        {label: 'Edit', name: EDIT, icon: Draw},
        {label: 'Json Preview', name: PREVIEW_JSON, icon: FileJson},
        {label: 'Chat Preview', name: PREVIEW_CHAT, icon: Chat},
        {label: 'Web Preview', name: PREVIEW_WEB, icon: WebPage},
        {label: 'Delete', name: DELETE, icon: Delete},
        {label: 'Form Experience Score', name: LH_SCORE, icon: EmailLightning}
];


export const JourneyActions = [
        {label: 'Get', name: PREVIEW_JSON, icon: ViewDetail},
        {label: 'ReTarget', name: TARGET, icon: Targeted}
];


export const FormColumns = [
        { name: 'Name', key: 'metadata.name' },
        { name: 'Description', key: 'metadata.description' },
        { name: 'Tags', key: 'metadata.tags' },
        { name: 'Version', key: 'metadata.version' }
    ];

export const JourneyColumns = [
        { name: 'Id', key: 'id' },
        { name: 'Form Name', key: 'journey.form.name' },
        { name: 'Lang', key: 'lang' },
        { name: 'Started', key: 'journey.started' },
        { name: 'Ended', key: 'journey.ended' }
    ];
