
import { ActionGroup, Button, Flex, Switch, Text, View } from "@adobe/react-spectrum";
import { useEffect, useState } from "react";
import { CORE_URL } from "../../utils/Constant";
import { Util } from "../../utils/Util";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";

interface EditorProp {
    formDef?: any;
    close?:Function;
}

const Editor = (props: EditorProp) => {

    let formDef:any;

    let closeHandler = () => {
        if(props.close) {
            props.close();
        }
    }

    let updateFormDef = (value:any) => {
        formDef = value;
    }
    let updateForm = async() => {
        Util.createOrUpdateForm(props?.formDef?.id, formDef);
        closeHandler();
    }

    return(
        <>
        <Flex direction="column" gap="size-100" margin={10} width="auto" height="100%">
            <Flex gap="size-100" width="100%" justifyContent="left">
                <Button variant='cta' onPress={updateForm}>
                    <Text>Save</Text>
                </Button>
                <Button variant='secondary' onPress={closeHandler}>
                    <Text>Close</Text>
                </Button>
            </Flex>
            <View>
            <AceEditor width="100%"
                    placeholder="Placeholder Text"
                    mode="json"
                    theme="github"
                    name="blah2"
                    onChange={updateFormDef}
                    fontSize={14}
                    showPrintMargin={true}
                    showGutter={true}
                    highlightActiveLine={true}
                    value={JSON.stringify(props.formDef, null, 2)}
                    setOptions={{
                        enableBasicAutocompletion: false,
                        enableLiveAutocompletion: false,
                        enableSnippets: false,
                        showLineNumbers: true,
                        tabSize: 2,
                    }}/>
                                
            </View>
        </Flex>
        </>
    );
}
export default Editor;