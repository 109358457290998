import React, { useState } from 'react';

import Editor from './Editor';
import Toolbar from '../common/Toolbar';
import { Content, Dialog, DialogContainer, Divider, Heading, Provider, useAsyncList, defaultTheme } from '@adobe/react-spectrum';
import { FormActions, FormColumns } from '../../utils/Data';
import { AF_RENDER_URL, CORE_URL, CREATE, DELETE, EDIT, HDFC_RENDER_URL, ICICI_RENDER_URL, HSBC_RENDER_URL, LH_SCORE, PREVIEW_CHAT, PREVIEW_JSON, PREVIEW_WEB, REFRESH } from '../../utils/Constant';
import FormList from '../common/ListView';
import ListView from '../common/ListView';
import {mappings} from '@aemforms/af-react-components'
import { AdaptiveForm } from '@aemforms/af-react-renderer';
import { Action } from '@aemforms/af-core';
import formDefJson from "../../forms/af.json"
import templateJson from "../../forms/template.json"
import { Util } from '../../utils/Util';


let Forms = () => {

    let [key, setKey] = useState<string>();
    let [showEditor, setEditorVisibility] = useState(false);
    let [formDef, setFormDef] = useState<any>();
    let [formDefMap, setFormDefMap] =  useState(new Map());
    let [showDialog, setShowDialog] = React.useState(false);

    let closeHandler = () => {
        setEditorVisibility(false);
        list.reload();
    }
    let setSelectedKey = (key:any) => {
        if(key && key.size > 0) {
          setKey(key.currentKey);
          let def = formDefMap.get(key.currentKey);
          setFormDef(def)
        } else {
          setKey(undefined);
          setFormDef(undefined);
        }
    }

    let actionHandler = async (action:string) => {
        switch(action) {
            case EDIT : 
                setEditorVisibility(true);
                break;
            case PREVIEW_WEB: 
                if(key && key.includes("hdfc"))
                  window.open(`${HDFC_RENDER_URL}${key}`)
                else if(key && key.includes("icici"))
                  window.open(`${ICICI_RENDER_URL}${key}`)
                else if(key && key.includes("hsbc"))
                  window.open(`${HSBC_RENDER_URL}${key}`)
                else 
                  window.open(`${AF_RENDER_URL}${key}`)
                break;
            case PREVIEW_CHAT:
                  let value = key?.substring(key.lastIndexOf("/")+1).replace("-", " ");
                  window.open(`https://wa.me/919289053103?text=Find ${value}`)
                  break;
            case PREVIEW_JSON: 
                window.open(`${CORE_URL}${key}`)
                break;
            case REFRESH: 
                 list.reload();
                break;
            case LH_SCORE:
                window.open(`${key}.html`)
                break;
            case CREATE:
              setShowDialog(true);
              break;
            case DELETE:
              if(key) {
                await Util.deleteForm(key);
                list.reload();
              }

        }
    }
    

    let list = useAsyncList<any>({
      async load() {
        let res = await fetch(`${CORE_URL}/listforms`);
        let forms = await res.json();

        let temp = new Map();
        for (const form of forms) {
          temp.set(form.id, form);
        }
        setFormDefMap(temp);
  
        return {
          items: forms
        };
      }
    });

    let createForm = async (action:Action) => {
      //@ts-ignore
      let data:any = action.target.exportData();
      templateJson.metadata.name = data.name;
      templateJson.metadata.description = data.description;
      templateJson.metadata.tags = data.tags;
      templateJson.action = `https://core.crispr-api.workers.dev/submit/content/forms/af/${data.name}`;
      await Util.createOrUpdateForm(`/content/forms/af/${data.name}`, JSON.stringify(templateJson));
      setShowDialog(false);
      list.reload();
    }

    return (
      <>
        <Heading level={1}>AEM Adaptive Form Headless</Heading>
      {(showEditor ? 
        <Editor formDef={formDef} close={closeHandler} /> : 
        <>
          <Toolbar items={FormActions} includeCreate={true} editable={formDef ? true : false}  actionHandler={actionHandler}/>
          <ListView uniqueKey='path' columns={FormColumns} list={list} setSelectedKey={setSelectedKey} />
          <DialogContainer onDismiss={() => setShowDialog(false)}>
            {showDialog ?
            <section className='form-container'>
              <Heading> Create Form</Heading>
              <Divider />
              <Content > 
                <Provider theme={defaultTheme} colorScheme={`light`}>
               <AdaptiveForm mappings={mappings} formJson={formDefJson} onSubmit={createForm} /></Provider>
              </Content>
              </section>: null}
          </DialogContainer>
        </>
      )}
      </>
    );
}


export default Forms;