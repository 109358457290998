import { CORE_URL } from "./Constant";

export class Util {

    public static timeAgo = (time:number) => {
        var time_formats = [
        [60, 'seconds', 1], // 60
        [120, '1 minute ago', '1 minute from now'], // 60*2
        [3600, 'minutes', 60], // 60*60, 60
        [7200, '1 hour ago', '1 hour from now'], // 60*60*2
        [86400, 'hours', 3600], // 60*60*24, 60*60
        [172800, 'Yesterday', 'Tomorrow'], // 60*60*24*2
        [604800, 'days', 86400], // 60*60*24*7, 60*60*24
        [1209600, 'Last week', 'Next week'], // 60*60*24*7*4*2
        [2419200, 'weeks', 604800], // 60*60*24*7*4, 60*60*24*7
        [4838400, 'Last month', 'Next month'], // 60*60*24*7*4*2
        [29030400, 'months', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
        [58060800, 'Last year', 'Next year'], // 60*60*24*7*4*12*2
        [2903040000, 'years', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
        [5806080000, 'Last century', 'Next century'], // 60*60*24*7*4*12*100*2
        [58060800000, 'centuries', 2903040000] // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
        ];
        var seconds = (+new Date() - time) / 1000,
        token = 'ago',
        list_choice = 1;
    
        if (seconds == 0) {
        return 'Just now'
        }
        if (seconds < 0) {
        seconds = Math.abs(seconds);
        token = 'from now';
        list_choice = 2;
        }
        var i = 0,
        format;
        while (format = time_formats[i++])
        if (seconds < format[0]) {
            if (typeof format[2] == 'string')
            return format[list_choice];
            else
            return Math.floor(seconds / format[2]) + ' ' + format[1] + ' ' + token;
        }
        return time;
  }

  public static getData = (item: any , columnKey: string) => {
    if(columnKey.includes(".")) {
        let keys = columnKey.split(".");
        let key = keys[0];
        let value : any = item;
        let i = 0;
        while(key && value && i < keys.length) {
        //@ts-ignore
            value = value[key];
            key = keys[++i];
        }
        if(value instanceof Array) {
            value = value.join(", ");
        }
        if( columnKey.includes("started") || columnKey.includes("ended")) {
            return Util.timeAgo(value);
        }
        return value;
    } else {
        //@ts-ignore
        return item[columnKey];
    }
    }
    public static createOrUpdateForm = async (id:string, formDef:any) => {
        const reqIngest = await fetch(
            `${CORE_URL}${id}`,
            {
              method: 'POST',
              headers: {
                'content-type': 'application/json'
              },
              body: (formDef)
            }
          );
          console.log("Post to Conversation Service Status " + reqIngest.status);
    }
    public static deleteForm = async (id:string) => {
        const reqIngest = await fetch(
            `${CORE_URL}${id}`,
            {
              method: 'DELETE'
            }
          );
          console.log("Delete Status " + reqIngest.status);
    }
}