import React from 'react';
import logo from './logo.svg';
import './App.css';

import {Button, defaultTheme, Grid, Provider, View} from '@adobe/react-spectrum';
import Forms from './components/form/Form';
import SideBar from './components/SideBar';
import { Route, Routes } from 'react-router-dom';
import Journey from './components/journey/Journey';

function App() {
  return (
    <Provider theme={defaultTheme} colorScheme={`light`}>
      <Grid
        areas={['sidebar content']}
        columns={['256px', '3fr']}
        rows={['auto']}
        height='100vh'
        gap='size-100'
      >
      <View
        gridArea='sidebar'
        backgroundColor='gray-200'
        padding='size-200'
      >
        <SideBar />
      </View>
      <View gridArea='content' padding='size-200'>
        <Routes>
          <Route path="/" element={<Forms />} />
          <Route path="journey" element={<Journey />} />
        </Routes>
      </View>
      </Grid>
    </Provider> 
  );
}

export default App;
