/* 
* <license header>
*/

import React from 'react'
import { NavLink } from 'react-router-dom'

function SideBar () {
  return (
    <ul className="SideNav">
      <li className="SideNav-item">
        <NavLink className="SideNav-itemLink" aria-current="page" exact="true" to="/">Headless Form (AF 2.0)</NavLink>
      </li>
      <li className="SideNav-item">
        <NavLink className="SideNav-itemLink"  aria-current="page" exact="true" to="/journey">Journey Tracker</NavLink>
      </li>
    </ul>
  )
}

export default SideBar
