

export const EDIT = "edit";
export const DELETE = "delete";
export const REFRESH = "refresh";
export const PREVIEW_JSON = "preview_json";
export const PREVIEW_WEB = "preview_web";
export const PREVIEW_CHAT = "preview_chat";
export const LH_SCORE = "lh_score";

export const TARGET = "target";
export const RESTART = "restart";
export const CREATE = "create";


export const CONVERSATION_URL = `https://conversation.adobe-aem--hackathon-8803.workers.dev/journey/`;
export const CORE_URL = `https://core.crispr-api.workers.dev/adobe/forms/af/v1`

export const AF_RENDER_URL =  `https://af.crispr-esr.workers.dev`
export const HDFC_RENDER_URL =  `https://hdfc.crispr-esr.workers.dev`
export const ICICI_RENDER_URL =  `https://icici.crispr-esr.workers.dev`
export const HSBC_RENDER_URL =  `https://hsbc.crispr-esr.workers.dev`