import {ActionButton, ActionGroup, ActionMenu, Button, ButtonGroup, Flex, Item, Keyboard, Menu, MenuTrigger, Text, View} from '@adobe/react-spectrum';
import Draw from '@spectrum-icons/workflow/Draw';
import Refresh from '@spectrum-icons/workflow/Refresh';
import React from 'react';
import { REFRESH, CREATE } from '../../utils/Constant';

interface ToolbarProp {
    items:Array<any>;
    editable?:boolean;
    includeCreate?:boolean;
    actionHandler?:Function
}
let Toolbar = (props: ToolbarProp)=>{


    let render = (item: any) => {
        let Icon = item.icon;
        return (
            <>
                <Icon />
                <Text>{item.label}</Text>
            </>
        ) 
    }
      
    let actionHandler = (action:React.Key) => {
        if(props.actionHandler) {
            props.actionHandler(action);
        }
    }

    return(
        <>
        <Flex gap="size-100" margin={10} width="auto">
            <Flex gap="size-100" width="50%">
                <ActionGroup isDisabled={!props.editable} items={props.items} onAction={(key) => actionHandler(key)}>
                    {item => <Item key={item.name}>{render(item)}</Item>}
                </ActionGroup>
            </Flex>
            <Flex gap="size-100" width="50%" justifyContent="end">
                <Button variant='secondary' onPress={() => actionHandler(REFRESH)}>
                    <Refresh />
                    <Text>Refresh</Text>
                </Button>
                {props.includeCreate ? <Button variant='cta' onPress={() => actionHandler(CREATE)}>
                    <Draw />
                    <Text>Create</Text>
                </Button> : null}
            </Flex>
        </Flex>
        </>
    );
}
export default Toolbar