import React, { useState } from 'react';

import { AlertDialog, DialogContainer, DialogTrigger, Heading, useAsyncList } from '@adobe/react-spectrum';
import { AF_RENDER_URL, CONVERSATION_URL, CORE_URL, EDIT, HDFC_RENDER_URL, PREVIEW_CHAT, PREVIEW_JSON, PREVIEW_WEB, REFRESH, RESTART, TARGET } from '../../utils/Constant';
import Toolbar from '../common/Toolbar';
import { JourneyActions, JourneyColumns } from '../../utils/Data';
import ListView from '../common/ListView';

let Journey = () => {

    let [journey, setJourney] = useState<any>();
    let [isOpen, setOpen] = React.useState(false);
    let [msg, setMsg] = useState<string>();
    let [journeyMap, setJourneyMap] =  useState(new Map());


    let actionHandler = async (action:string) => {
        switch(action) {
            case RESTART : 
                await fetch(`${CONVERSATION_URL}${journey.id}/${journey.id}/start`)
                .then(() => {
                  setMsg("Restarted Session.")
                  setOpen(true);
                });
                break;
            case PREVIEW_JSON: 
                window.open(`${CONVERSATION_URL}${journey.id}/get`);
                break;
            case REFRESH: 
                  list.reload();
                break;
            case TARGET: 
                await fetch(`${CONVERSATION_URL}${journey.id}/resume`)
                .then(() => {
                  setMsg("Initiated Targeting.")
                  setOpen(true);
                });
                break;
        }
    }
    
    let setSelectedKey = (key:any) => {
      if(key && key.size > 0) {
        setJourney(journeyMap.get(key.currentKey))
      } else {
        setJourney(undefined);
      }
  }

    let list = useAsyncList<any>({
      async load() {
        let res = await fetch(`${CONVERSATION_URL}`);
        let journey = await res.json();
  
        let map = new Map();
        if(journey?.contexts) {
          for(let context of journey?.contexts) {
            map.set(context.id, context);
          }
          setJourneyMap(map);
        }
        return {
          items: journey?.contexts
        };
      }
    });

    return (
      <>
        <Heading level={1}>Journey Tracker</Heading>
          <Toolbar items={JourneyActions} includeCreate={false} editable={journey ? true : false}  actionHandler={actionHandler}/>
          <ListView uniqueKey='id' columns={JourneyColumns} list={list} setSelectedKey={setSelectedKey} />
          <DialogContainer onDismiss={() => setOpen(false)} >
            {isOpen &&
              <AlertDialog
                title="Info"
                variant="destructive"
                primaryActionLabel="Ok">
                {msg}
              </AlertDialog>
          }
          </DialogContainer>
      </>
    );
}


export default Journey;